<template>
  <v-content>
    <router-view />
  </v-content>
</template>

<script>
export default {
  name: "InternalCoreView",

  components: {
    // DashboardCoreFooter: () => import("./Footer"),
  },
};
</script>
